import { useContext, useEffect, useState } from "react";
import fetch from "cross-fetch";
import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { AzureDriverGarage, AzureFuelTruckFillup, AzureGarage, AzureServicableArea, AzureServicableZipCodes, Garage } from "../../models/garage";
import { UserContext } from "../../contexts/UserContext";
import { db } from "../../firebaseutil";

export const baseURL =
  "https://paleoservices20210103010912-v2prod.azurewebsites.net/api";
export const AppID = "customerapp";
export const AppIDDriver = "driverapp";
export const GARAGE = "garage";

export const getRequestMethod = async (URL: any) => {
    const response = await fetch(`${baseURL}${URL}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        AppID,
      },
    });
    const json = await response.json();      
    return json;
  };

  export const getAzureGarages = async() => {
    return await getRequestMethod("/ServiceArea/GetGarages") as AzureGarage[];
  }
  
  export const getAzureDriverGarages = async() => {
    return await getRequestMethod("/ServiceArea/GetDriverGarages") as any[];
  }
  
  export const getAzureServicableZips = async() => {
    return await getRequestMethod("/Customers/GetServicableZips") as AzureServicableZipCodes[];
  }
  
  export const getAzureServicableAreas = async() => {
    return await getRequestMethod("/ServiceArea/GetServiceArea") as AzureServicableArea[];
  }

  export const GetAzureFuelTruckFillUps = async() => {
    return await getRequestMethod("/Drivers/GetFuelTruckFillUps") as AzureFuelTruckFillup[];
  }  
  
export const useGarages = () => {
    const [azureGarages, setAzureGarages] = useState<AzureGarage[]>([]);
    const [driverGarages, setDriverGarages] = useState<AzureDriverGarage[]>([]);
    const [serviceZipCodes, setServicableZipCodes] = useState<AzureServicableZipCodes[]>([]);
    const [servicableAreas, setServicableAreas] = useState<AzureServicableArea[]>([]);
    const [garages, setGarages] = useState<Garage[]>([]);
    const {user} = useContext(UserContext);

    const [fetch1, setFetch1] = useState(false);
    const [fetch2, setFetch2] = useState(false);
    const [fetch3, setFetch3] = useState(false);

    useEffect(() => {
        if(azureGarages?.length === 0){
            (async() => {
                const result = await getAzureGarages();
                setAzureGarages(result);
            })()
        }
    }, [azureGarages])

    useEffect(() => {
        if(driverGarages?.length === 0 && !fetch1){
            (async() => {
                const result = await getAzureDriverGarages();
                setDriverGarages(result);
                setFetch1(true);
            })()
        }
    }, [driverGarages, fetch1])

    useEffect(() => {
        if(serviceZipCodes?.length === 0 && !fetch2){
            (async() => {
                const result = await getAzureServicableZips();
                setServicableZipCodes(result);
                setFetch2(true);
            })()
        }
    }, [serviceZipCodes, fetch2])

    useEffect(() => {
        if(servicableAreas?.length === 0 && !fetch3){
            (async() => {
                const result = await getAzureServicableAreas();
                setServicableAreas(result);
                setFetch3(true);
            })()
        }
    }, [servicableAreas, fetch3])

    const appendGarage = async (add: Garage) => {
      await setGarages(prev => [...prev.filter(pk => pk.garageId !== add.garageId), add]);
    };
    
  useEffect(() => {
    if (!user?.uid) {
      setGarages([]);
    }
  }, [user?.uid]);
    
  useEffect(() => {
    const q = query(collection(db, `${GARAGE}`), where("status", "==", "active"));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendGarage(change.doc.data() as Garage);
        } else if (change.type === "modified") {
          appendGarage(change.doc.data() as Garage);
        } else if (change.type === "removed") {
          setGarages(prev =>
            prev.filter(p => p?.garageId !== change.doc.data().garageId)
          );
        }
      });
    });
    return unsubscribe;
  }, []);

    return {
        azureGarages: azureGarages?.filter(i => !!garages?.find(j => j?.garageId === i?.garageId)),
        garages: garages?.filter(i => i?.status === "active"),
        driverGarages,
        serviceZipCodes,
        servicableAreas,
    }
}