import {Footer} from "./footer";
import { FloatingFooterWrapper } from "./footer-style";

export const FloatingFooter = () => {

  return (
    <FloatingFooterWrapper>
      <Footer />
    </FloatingFooterWrapper>
  )
}