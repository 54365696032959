export const firebaseConfig = {
    apiKey: "AIzaSyANLVyA_9PQMQyWYLaaTK8z8WIFZa3UUXk",
    authDomain: "paleo-car-care.firebaseapp.com",
    databaseURL: "https://paleo-car-care-default-rtdb.firebaseio.com",
    projectId: "paleo-car-care",
    storageBucket: "paleo-car-care.appspot.com",
    messagingSenderId: "372512966140",
    appId: "1:372512966140:web:858ca64a2d348a0e6f9e79",
    measurementId: "G-F3GBKLF9CJ",
    analyticsId:"G-F3GBKLF9CJ"
};