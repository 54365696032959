import React, { createContext, useEffect, useState } from 'react'
import { DbTechnician, DbUser, PublicProfileByHandle } from '../models/user';
import useFirebaseUsers from '../hooks/user/useFirebaseUsers';
import { useFirebaseLogin } from '../hooks/user/useFirebaseLogin';
import { User } from 'firebase/auth';
import useFirebase from '../hooks/user/useFirebase';

interface IUserContext {
  dbTechnicians: DbTechnician[];
  user: User | undefined;
  emailLogin: (email: string, password: string) => Promise<any>;
  emailSignUp: (email: string, password: string) => Promise<any>;
  forgotPassword: (email: string) => Promise<any>;
  emailLogout: () => void;
  dbTechnician?: DbTechnician;
  dbUser?: DbUser;
  userToken: string | null;
  isAuthenticated: boolean;
  setDbTechnicians: (d: DbTechnician[]) => void;
  setDbTechnician: (d: DbTechnician) => void;
  setDbUser: (d: DbUser) => void;
  isNewUser: boolean;
  signInSignUpInProgress: boolean;
  isLoading: boolean;
  isSigningUp: boolean;
  searchTermCustomers: string;
  searchTermTechnicians: string;
  setSearchTermCustomers: (s: string) => void;
  setSearchTermTechnicians: (s: string) => void;
  fetchPublicProfileByHandle: (handle: string) => Promise<PublicProfileByHandle | undefined>;
  fetchDbTechnician: (uid: string) => Promise<DbTechnician | undefined>;
  userExists: (phoneNumber: string) => Promise<boolean>;
  technicianExists: (phoneNumber: string) => Promise<boolean>;
  markSignUpComplete: () => void;
  updateUserEmail: (email: string) => Promise<string>;
  fetchDbUser: (uid: string) => Promise<DbUser | undefined>;
  setIsSigningUp: (data: boolean) => void;
  dbCustomers: DbUser[];
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider = ({ children }: { children: any }) => {

  const { user, emailLogin, emailLogout, forgotPassword, emailSignUp } = useFirebaseLogin();
  const { isAuthenticated, fetchPublicProfileByHandle,
    dbTechnician, dbUser, fetchDbTechnician, setDbTechnician, setDbUser,
    fetchDbUser, isLoading, isNewUser, isSigningUp,
    markSignUpComplete, setIsSigningUp, signInSignUpInProgress,
    updateUserEmail, userExists, technicianExists, userToken } = useFirebase();


  const {
    dbTechnicians,
    searchTermCustomers,
    searchTermTechnicians,
    setSearchTermCustomers,
    setSearchTermTechnicians,
    setDbTechnicians,
    dbCustomers,

  } = useFirebaseUsers(dbTechnician);


  return (
    <UserContext.Provider value={{
      dbTechnicians,
      user,
      searchTermCustomers, searchTermTechnicians, setSearchTermCustomers, setSearchTermTechnicians,
      emailLogin,
      emailLogout,
      emailSignUp,
      forgotPassword,
      dbUser, userToken, isAuthenticated,
      isNewUser, signInSignUpInProgress, isLoading,
      markSignUpComplete, updateUserEmail, fetchDbUser,
      userExists, setIsSigningUp, isSigningUp, fetchDbTechnician,
      dbTechnician, fetchPublicProfileByHandle,
      setDbTechnicians, technicianExists,
      setDbTechnician,
      dbCustomers,
      setDbUser,
    }}>
      {children}
    </UserContext.Provider>
  )
}