import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseConfig } from "./firebase-config";
import {getMessaging, onMessage} from "firebase/messaging";

let firebaseApp: FirebaseApp;

if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApps()[0];
}

let db = initializeFirestore(firebaseApp, {experimentalForceLongPolling: true, ignoreUndefinedProperties: true})


const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();
auth.settings.appVerificationDisabledForTesting = false;
const functions = getFunctions(firebaseApp);

export {db, auth, functions, firebaseApp};
