import styled from "styled-components";
import patternBg from '../public/images/pattern-bg.png';
export const AccordionWrapper = styled.div`
.table thead th {

border-bottom: 2px solid #dee2e6 !important;
}
.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
  display:none;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height:8px;
    width: 8px;
    margin-left: 4px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
 
}
.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before{transform: rotate(45deg);}
.accordion__button{
    background-color: transparent;
    color:#000;
    font-weight:500;
}
.accordion__panel{padding:10px 20px}

.accordion {
 border:1px solid #EAEAEA;
    border-left: 0;
    border-right: 0;
}
@media(max-width:767px){
    /* .accordion__button{text-align:center} */
}
`;
export const HeaderWrapper = styled.div`
&.scrolled{ position:fixed;
    z-index: 12;
    left: 0;
    right: 0;top:0}
  .searchwrapper {
    border:1px solid rgb(234, 234, 234);
    border-radius:5px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  .location {
    @media (max-width: 500px) {
      width: 35%;
      font-size: 0.8rem;
    }
  }
  .ais-SearchBox-submit {
    float: left;
    width: 4%;
    padding: 6px 2%;
    background: #fff;
    color: rgb(68, 68, 68);
    font-size: 1rem;
    border: 0;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 5px;
    cursor: pointer;
  }

  .ais-SearchBox-submit:hover {
    color: #1478DD
  }

  .ais-SearchBox-input {
    margin-left:5px;
    padding: 6px 10px;
    font-size: 1rem;
    border: 0;
    float: left;
    width: 92%;
    background: #fff;
    border-radius: 5px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      font-size: 1rem;
    }
  
  }

  .ais-SearchBox-reset {
    display: none;
  }

  @media (max-width: 700px) {
    .ais-SearchBox-input {
      width: 90%;
    }
    .ais-SearchBox-submit {
      width: 10%;
    }
  }
  @media (max-width: 500px) {
    a {
      .ais-SearchBox-input {
        width: 85%;
      }
      .ais-SearchBox-submit {
        width: 15%;
      }
    }
  }


  background-color: #2b2b2b;
  a {
    color: #d3d3d3;
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .displayName {
    width: 38px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    // background: #1478DD;
    border:2px solid #1478DD;
    &:hover {
      text-decoration: none;
      background: #fff;
      color: #1478DD;
    }
  }

  @media (max-width: 500px) {
    a {
      font-size: 1.2rem;
    }
    svg {
      max-width: 30px;
    }
  }
`;

export const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 300px;
  margin-bottom: 0px;

  @media (max-width: 1199px) {
    height: 300px;
  }

  @media (max-width: 991px) {
    height: 212px;
  }

  @media (max-width: 768px) {
  }
  @media (width: 768px) {
  }
  @media (max-width: 600px) {
    height: 212px;
  }
`;

export const FooterWrapper = styled.div`
// &.sticky{position: sticky;left:0;bottom:0;right:0;z-index:1000;padding:15px 0};
  padding: 40px 0;
  background: #2b2b2b;
  color: #fff;
  .footer-col {
    padding-right: 10px;
  }
  h5 {
    color: #29ac49;
    font-size: 0.9rem;
    font-weight: 400;
    padding-bottom: 15px;
  }
  li {
    font-size: 0.8rem;
    padding-bottom: 8px;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #fff;
    text-decoration: underline;
  }
  @media (max-width: 400px) {
    .footer-col {
      width: 33.3333%;
    }
    .footer-col:last-child {
      width: 100%;
    }
  }
`;

export const MobileNavWrapper = styled.div`
  &.visible {
    width: 100%;
    visibility: visible;
    opacity: 1;
    overflow: visible;
    transition: width 0.1s ease-in-out, visibility 0.6s, opacity 0.2s;
    .pdt-wrapper {
      width: 240px;
      transition: width 0.2s ease-in-out;
    }
  }
  width: 0;
  opacity: 0;
  overflow: visible;
  visibility: hidden;
  position: absolute;
  top: 0px;
  bottom: 0;
  z-index: 1;
  background-color: rgb(0 0 0 / 60%);
  z-index: 11;
  .pdt-wrapper {
    width: 0;
    height: 100vh;
    position: relative;
    background: #f9f9f9;
  }
  ul {
    li {
      padding-bottom: 2px;
      color: #656565;
      display: flex;
      a {
        color: #656565;
        text-decoration: none;
        cursor: pointer;
      }
      a:hover {
        text-decoration: underline !important;
      }
    }
  }
  .close {
    position: absolute;
    right: -32px;
    top: 10px;
  }

  input.newsletter-input {
    border: none !important;
    background: #f1f1f1 !important;
    color: #000 !important;
    padding: 0.5rem 1rem 0.5rem 1rem !important;
    width: 90% !important
  }
`;

export const MembershipCardWrapper = styled.div`
margin-top:60px;
@media (min-width:768px){
  .card-header:nth-child(1){
    height:180px;
}

// .membership-card-grid{
//   display:grid;
//   grid-template-columns:repeat(4,300px);
//   justify-content:center;
// }

.question-list{
  text-align:right;
}
.membership-card-grid{ 
  width: 650px;
}
}

.question-list{
 cursor:pointer;
}
.about-plan{
  height:30px;
}
.bg-grey{
  background-color:#d2d2d2;
}
.themed-bg{
  background-image:url('${patternBg.src}');
  background-repeat:no-repeat;
  background-size:cover;
}
.title-wrapper{
  height:150px;
  transform:translateY(5%)
}
.title{
  font-size:2.2rem;
  font-weight:900;
  border-bottom:2px solid #fff;
}
.price{
  font-size:1.6rem;
}
`