
import logo from "../../public/images/paleo-logo.svg";
import { useContext, useMemo } from "react";
import Link from "next/link";
import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { AiOutlineMenu, AiOutlineShop } from "react-icons/ai";
import { toggleMenuAction } from "../../store/slices/common.slice";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenuSelector } from "../../store/selectors/common.selectors";
import { UserContext } from "../../contexts/UserContext";
import { HeaderWrapper } from "../../styles/global-style";
import { DropdownMenuWrapper } from "../../components/menu/dropdown/dropdown-style";
import { DropdownMenu } from "../../components/menu/dropdown/dropdownMenu";
import { initials } from "../../utils/util";
import UseWindowSize from "../../hooks/helpers/useWindowSize";
import dynamic from "next/dynamic";
import { auth } from "../../firebaseutil";

const Header = () => {


  const { user, dbUser, setIsSigningUp, isSigningUp, dbTechnician } = useContext(UserContext);
  const [scrolled, setScrolled] = useState(false);
  const [current, setcurrent] = useState("");
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const screenProps = UseWindowSize();
  const pathName = router?.pathname;

  console.log({pathName});
  

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: { target: any; }) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (toggleDropDown) { toggleDropDownMenu() }

        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, toggleDropDown]);
  }


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("scroll down", handleScroll);
    };
  })

  const signInSignUpProfileUpdate = (isSigningUp: boolean) => {
    if (!user) {
      setIsSigningUp(isSigningUp);
      router.push("/sign-in");
    }
    else {
      //TODO: Show Profile menu, for now, show step 2 of the signup page itself.
      // props.openModal();
      toggleDropDownMenu();
    }
  }

  const signInSignUp = (isSigningUp: boolean) => {
    setIsSigningUp(isSigningUp);
    router.push("/sign-in");
  }

  const toggleMenu = useSelector(toggleMenuSelector);


  const showMenuHandler = () => {
    dispatch(toggleMenuAction(!toggleMenu));
  };

  const toggleDropDownMenu = () => {

    if (user !== null) {
      setToggleDropDown(!toggleDropDown);
    }
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);


  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 0) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }


  }

  let toggleScroll = [''];
  if (scrolled) {
    toggleScroll.push('scrolled');
  }

  return (
    <HeaderWrapper className={toggleScroll.join(" ")}>
      <header>
        <div className="container py-3 px-lg-4 max-w-100">
          <div className="mx-sm-5 d-flex justify-content-between align-items-center">
            <div className="left-section d-none align-items-center d-sm-none">
              <div className="me-3">
                <Link href="/" passHref className="d-flex align-items-start"><a>
                  <img src={logo.src} className="img-fluid" style={{ height: 50, width: 64, objectFit: 'contain' }} alt="Logo" />
                </a>
                </Link>
              </div>

            </div>
            <div className="left-section align-items-center d-none d-lg-flex">
              <div className="me-3" style={screenProps?.windowSize?.width > 1500 ? { marginLeft: 30, flexShrink: 0 } : { flexShrink: 0 }}>
                <Link href="/" passHref><a className="d-flex align-items-start">
                  <img src={logo.src} className="img-fluid" style={{ height: 50, width: 64, objectFit: 'contain' }} alt="Logo" />
                </a>
                </Link>
              </div>
              <Link href="/garageworks.html" passHref >
                <a style={{ whiteSpace: "nowrap" }} className={`${pathName?.startsWith('/garageworks.html') ? 'fw-bold' : 'fw-normal'} mx-3`}>{`Service & Repair`}</a>
              </Link>
              <Link href="/carcreditprogram.html" passHref >
                <a style={{ whiteSpace: "nowrap" }} className={`${pathName?.startsWith('/carcreditprogram.html') ? 'fw-bold' : 'fw-normal'} mx-3`} >{`Gas Delivery`}</a>
              </Link>
              <Link href="/fleetrepairandmaintenance.html" passHref >
                <a style={{ whiteSpace: "nowrap" }} className={`${pathName?.startsWith('/fleetrepairandmaintenance.html') ? 'fw-bold' : 'fw-normal'} mx-3`} >{`Fleet Management`}</a>
              </Link>
              <Link href="/membership" passHref >
                <a style={{ whiteSpace: "nowrap" }} className={`${pathName?.startsWith('/membership') ? 'fw-bold' : 'fw-normal'} mx-3`} >{`Membership`}</a>
              </Link>
            </div>
            <div className="col-md-12 col-lg-5 text-right order-lg-2 logo-wrapper">
              <div className="d-flex align-items-center w-auto d-lg-none mb-2">
                <AiOutlineMenu color="#000" size={24} onClick={showMenuHandler} className="cursor-pointer me-3" />
                <Link href="/" passHref className="d-flex align-items-start" onClick={() => { }}><a>
                  <img src={logo.src} className="img-fluid" style={{ height: 60, width: 60, objectFit: 'contain' }} alt="Logo" />
                </a>
                </Link>
              </div>
              <ul className="list-unstyled d-flex  justify-content-end align-items-center font-md m-0" ref={wrapperRef}>
                <li className="d-flex d-none d-lg-flex">
                  {!dbTechnician?.isActive ?
                    <Link href="/become-technician" passHref><a style={{ whiteSpace: "nowrap" }} className={`${pathName?.startsWith('/become-technician') ? 'fw-bold' : 'fw-normal'} mx-3`} >We’re hiring! Join our team!</a></Link>
                    : <></>}
                </li>
                {/* <li className="d-none d-lg-flex">
                  {!!dbTechnician?.isActive &&
                    <Link href="/t/" passHref><a style={{ whiteSpace: "nowrap" }} onClick={() => setActiveTab('technician-hub')} className={`${activeTab == 'technician-hub' ? 'fw-bold' : 'fw-normal'} mx-3`} >
                      {`Technician Hub`}
                    </a>
                    </Link>}
                </li> */}
                <li className="px-3 pe-0 pe-md-2 cursor-pointer d-flex align-items-center" onClick={() => signInSignUpProfileUpdate(false)}>
                  {toggleDropDown && <DropdownMenuWrapper
                    className={current === "profile" ? "visible" : "visible"}
                  >
                    {
                      <DropdownMenu signInSignUp={signInSignUp} />
                    }
                  </DropdownMenuWrapper>}
                  <>

                    {user && (dbUser?.image || user?.photoURL) &&
                      <img onClick={() => signInSignUpProfileUpdate(false)} src={dbUser?.image ?? user?.photoURL ?? ""} alt="User-Photo" width={32} height={32} className="rounded-circle" />
                    }
                    {user && !dbUser?.image && !user?.photoURL &&
                      <a onClick={() => signInSignUpProfileUpdate(false)} className={dbUser?.firstName ? "displayName" : ""}>{(dbUser?.firstName?.[0] ?? "")?.toUpperCase()}</a>
                    }
                    {user && !dbUser?.image && !user?.photoURL && !dbUser?.firstName &&
                      <img onClick={() => signInSignUpProfileUpdate(false)} src="/user-profile-svgrepo-com.svg" alt="User-Profile" width={32} height={32} className="rounded-circle" />
                    }
                  </>
                  {auth?.currentUser?.uid && <p onClick={() => signInSignUpProfileUpdate(false)} className="ms-2 mb-0 bold">Hi, {dbUser?.firstName}</p>}
                </li>

              </ul>
            </div>

          </div>
        </div>
      </header>
    </HeaderWrapper >
  );
};
export default Header;

