import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";
import { MobileNavWrapper } from "../../styles/global-style";
import { toggleMenuAction } from "../../store/slices/common.slice";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenuSelector } from "../../store/selectors/common.selectors";
import { AiOutlineClose } from "react-icons/ai";
import { useRouter } from "next/router";
import logo from "../../public/images/paleo-logo.svg";
import { UserContext } from "../../contexts/UserContext";

export default function MobileNavigation() {
  const dispatch = useDispatch();
  const toggleMenu = useSelector(toggleMenuSelector);
  const hideMenu = () => {
    dispatch(toggleMenuAction(!toggleMenu));
  };
  const { dbTechnician } = useContext(UserContext);  

  const [activeTab, setActiveTab] = useState('');
  const router = useRouter();

  useEffect(() => {
    setActiveTab(router?.asPath?.slice(1, router?.asPath?.length));
  }, [])
  return (
    <MobileNavWrapper className={`${toggleMenu ? "visible" : ""
      } d-lg-none`}>
      <div className="pdt-wrapper  pt-4 px-3 pr-5">
        <AiOutlineClose
          size={24}
          color="#fff"
          onClick={hideMenu}
          className="close cursor-pointer"
        />
        <div className="me-3 d-flex align-items-center" style={{ gap: 10 }}>
          <Link href="/" passHref className="d-flex align-items-start"><a>
            <img src={logo.src} className="img-fluid" style={{ height: 50, width: 64, objectFit: 'contain' }} alt="Logo" />
          </a>
          </Link>
          {/* <p className="mb-0 fw-bold">Paleo Technician Dashboard</p> */}
        </div>
        <div className="d-flex flex-column mt-5" style={{ gap: 5 }}>
          <Link href="/garageworks.html" passHref >
            <a style={{ whiteSpace: "nowrap" }} onClick={() => setActiveTab('car-repair-service')} className={`${activeTab == 'car-repair-service' ? 'fw-bold' : 'fw-normal'} mx-3`}>{`Service & Repair`}</a>
          </Link>
          <br />
          <Link href="/carcreditprogram.html" passHref >
            <a style={{ whiteSpace: "nowrap" }} onClick={() => setActiveTab('fuel-delivery')} className={`${activeTab == 'fuel-delivery' ? 'fw-bold' : 'fw-normal'} mx-3`} >{`Gas Delivery`}</a>
          </Link>
          <br />
          <Link href="/fleetrepairandmaintenance.html" passHref >
            <a style={{ whiteSpace: "nowrap" }} onClick={() => setActiveTab('fleet-managment')} className={`${activeTab == 'fleet-managment' ? 'fw-bold' : 'fw-normal'} mx-3`} >{`Fleet Management`}</a>
          </Link>
          <br />
          <Link href="/membership" passHref >
            <a style={{ whiteSpace: "nowrap" }} onClick={() => setActiveTab('membership')} className={`${activeTab == 'membership' ? 'fw-bold' : 'fw-normal'} mx-3`} >{`Membership`}</a>
          </Link>
          <br />
          <Link href="/contactus.html" passHref >
            <a style={{ whiteSpace: "nowrap" }} onClick={() => setActiveTab('contactus')} className={`${activeTab == 'contactus' ? 'fw-bold' : 'fw-normal'} mx-3`} >{`Contact us`}</a>
          </Link>
          <br />
          {!dbTechnician?.isActive ?
            <Link href="/become-technician" passHref>
              <a style={{ whiteSpace: "nowrap" }} onClick={() => setActiveTab('become-technician')} className={`${activeTab == 'become-technician' ? 'fw-bold' : 'fw-normal'} mx-3`} >We’re hiring! Join our team!</a>
            </Link>
            : <></>}
          {/* {!!dbTechnician?.isActive &&
            <Link href="/t/" passHref>
              <a style={{ whiteSpace: "nowrap" }} onClick={() => setActiveTab('technician-hub')} className={`${activeTab == 'technician-hub' ? 'fw-bold' : 'fw-normal'} mx-3`} >
                {`Technician Hub`}
              </a>
            </Link>} */}
        </div>
      </div>

    </MobileNavWrapper>
  );
}
