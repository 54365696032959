import { useContext, useEffect, useState } from "react";
import "firebase/auth";
import {
  collection,
  getDocs,
  onSnapshot,
  getDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import {  db, functions } from "../../firebase/firebaseutil";
import { CustomerType, JobHistory, JobRequest, MarkAsCompleteCustomerModel, NEW_STATUS, ProposalAcceptRequestType, ProposalStatus, ProposalType, SystemSettings, UpdateStatusAndCatagory } from "../../models/jobs";
import { PROPOSAL } from "./useJobProposals";
import { httpsCallable } from "firebase/functions";
import { UserContext } from "../../contexts/UserContext";

const JOB = "job";
const CUSTOMER = "customer";
const SETTINGS = "settings";
const SYSTEM_SETTINGS = "system-settings";
const RequestType = {
  jobCompletedTechnician: "job-completed-technician",
  sendProposal: "send-proposal",
  saveSystemSettings: "saveSystemSettings",
  sendUpdatedProposal: "send-updated-proposal",
  jobCompletedCustomer: "job-completed-customer",
  updateJobStatusAndCatagory: "update-job-status-and-catagory",
  getJobHistory: "getJobHistory",
  acceptProposal: "accept-proposal",
}

export default function useJobs() {

  const {user, dbTechnician} = useContext(UserContext);
  const [jobs, setJobs] = useState<JobRequest[]>([]);
  const [searchJobText, setSearchJobText] = useState("");
  const [systemSettings, setSystemSetings] = useState<SystemSettings>();
  const [customerJobs, setCustomerJobs] = useState<JobRequest[]>([]);

  const appendJob = (add: JobRequest) => {
    setJobs(prev => [...prev.filter(pk => pk.id !== add.id), add]);
  };

  const appendCustomerJob = (add: JobRequest) => {
    setCustomerJobs(prev => [...prev.filter(pk => pk.id !== add.id), add]);
  };

  useEffect(() => {
    if (!user?.uid) {
      setJobs([]);
    }
  }, [user?.uid]);

  useEffect(() => {
    if (!user?.uid) return;
    if(dbTechnician?.isAdmin){
    const q = query(collection(db, `${JOB}`));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendJob(change.doc.data() as JobRequest);
        } else if (change.type === "modified") {
          appendJob(change.doc.data() as JobRequest);
        } else if (change.type === "removed") {
          setJobs(prev => prev.filter(p => p.id !== change.doc.data().id));
        }
      });
    });
    return unsubscribe;
    }
  }, [user?.uid, dbTechnician]);


  useEffect(() => {
    if (!user?.uid) return;
    const q = query(collection(db, `${JOB}`), where("userId", "==", user?.uid));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendCustomerJob(change.doc.data() as JobRequest);
        } else if (change.type === "modified") {
          appendCustomerJob(change.doc.data() as JobRequest);
        } else if (change.type === "removed") {
          setCustomerJobs(prev => prev.filter(p => p.id !== change.doc.data().id));
        }
      });
    });
    return unsubscribe;
  }, [user?.uid]);

  useEffect(() => {
    if (!user?.uid) return;
    if(dbTechnician?.userId && !dbTechnician?.isAdmin){
      const q = query(collection(db, `${JOB}`), where("assignedTechnician", "==", user?.uid));
      const unsubscribe = onSnapshot(q, snapshot => {
        snapshot.docChanges().forEach(change => {
          if (change.type === "added") {
            appendJob(change.doc.data() as JobRequest);
          } else if (change.type === "modified") {
            appendJob(change.doc.data() as JobRequest);
          } else if (change.type === "removed") {
            setJobs(prev => prev.filter(p => p.id !== change.doc.data().id));
          }
        });
      });
      return unsubscribe;
    }
  }, [user?.uid, dbTechnician]);

  useEffect(() => {
    if (!user?.uid) return;
    if(dbTechnician?.userId && !dbTechnician?.isAdmin){
      const q = query(collection(db, `${JOB}`), where("assignedDiagnostician", "==", user?.uid));
      const unsubscribe = onSnapshot(q, snapshot => {
        snapshot.docChanges().forEach(change => {
          if (change.type === "added") {
            appendJob(change.doc.data() as JobRequest);
          } else if (change.type === "modified") {
            appendJob(change.doc.data() as JobRequest);
          } else if (change.type === "removed") {
            setJobs(prev => prev.filter(p => p.id !== change.doc.data().id));
          }
        });
      });
      return unsubscribe;
    }
  }, [user?.uid, dbTechnician]);

  useEffect(() => {
    if(!user && systemSettings)
      return;
    else {
      (async() => {
        const docRef = doc(db, SETTINGS, SYSTEM_SETTINGS);
        const result = await getDoc(docRef);
        setSystemSetings(result?.data() as SystemSettings);
      })()
    }
  }, [user])

    const getJobsForCustomer = async(id: string) => { 
      const q = await getDocs(query(collection(db, JOB), where("userId", "==", id)));
      const jobs: JobRequest[] = [];
      await q.forEach((doc) => {
        jobs?.push(doc?.data() as JobRequest);
      });
      return jobs;
    }

    const getJobsForTechnicians = async(id: string) => {
      const q = await getDocs(query(collection(db, JOB), where("assignedTechnician", "==", id)));
      const jobs: JobRequest[] = [];
      await q.forEach((doc) => {
        jobs?.push(doc?.data() as JobRequest);
      });
      return jobs;
    }

    const getJobsForDiagnosticians = async(id: string) => {
      const q = await getDocs(query(collection(db, JOB), where("assignedDiagnostician", "==", id)));
      const jobs: JobRequest[] = [];
      await q.forEach((doc) => {
        jobs?.push(doc?.data() as JobRequest);
      });
      return jobs;
    }

    const getProposalsForJob = async(id: string) => {
      const q = await getDocs(query(collection(db, JOB, id, PROPOSAL)));
      const proposals: ProposalType[] = [];
      await q.forEach((doc) => {
        proposals?.push(doc?.data() as ProposalType);
      });
      return proposals;
    }
    const fetchCustomer = async (id: string) => {
      if(id) {
        const docRef = doc(db, CUSTOMER, id);
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
          return docSnap.data() as CustomerType;
        } else {
          return null;
        }        
      } else {
        return null;
      }  
    }

    const ChangeStatusAndCatagories = async(data: UpdateStatusAndCatagory) => {
      try {
        const callable = await httpsCallable(functions, "jobCall");        
        (await callable({requestType: RequestType.updateJobStatusAndCatagory, input: data }));  
        return true;
      } catch (e) {        
        return false;
      }
    }
    const sendProposal = async(data: ProposalType) => {
      try {
        const callable = await httpsCallable(functions, "jobCall");
        (await callable({requestType: RequestType.sendProposal, input: data }));  
        return true;
      } catch (e) {
        return false;
      }
    }

    const markAsCompleteTechnician = async(data: any) => {
      try{ 
        const callable = await httpsCallable(functions, "jobCall");
        (await callable({requestType: RequestType.jobCompletedTechnician, input: data }));
        return true;  
      }catch(e) {      
        return false;
      }
    }  

    const saveSettingsInDb = async(data: SystemSettings) => {
      try{ 
        const callable = await httpsCallable(functions, "jobCall");
        (await callable({requestType: RequestType.saveSystemSettings, input: data }));
        const docRef = doc(db, SETTINGS, SYSTEM_SETTINGS);
        const result = await getDoc(docRef);
        await setSystemSetings(result?.data() as SystemSettings);
        return true;  
      }catch(e) {      
        return false;
      }
    }

    const markAsCompleteCustomer = async(data: MarkAsCompleteCustomerModel) => {
      try {
        const callable = await httpsCallable(functions, "jobCall");
        (await callable({requestType: RequestType.jobCompletedCustomer, input: data }));
        return true;
      }
      catch (e) {
        return false;
      }
    }
  
    const getJobHistory = async(jobId: string) => {
      try {
        const callable = await httpsCallable(functions, "jobCall");
        const result = (await callable({requestType: RequestType.getJobHistory, input: jobId }));      
        return result?.data as JobHistory[];
      }
      catch (e) {      
        return [];
      }
    }

    const acceptProposal = async ({proposal}: {proposal: ProposalType;}) => {
      try {
        const proposalAcceptRequest: ProposalAcceptRequestType = {
          lastModified: new Date().toISOString(),
          status: NEW_STATUS.CustomerApproved,
          proposal: { ...proposal, status: ProposalStatus.accepted },
          assignedTechnician: proposal.technicianId,
          // paymentIntentId,
          // paymentMethodId
        };
  
        const callable = await httpsCallable(functions, "jobCall");
        (await callable({requestType: RequestType.acceptProposal, input: proposalAcceptRequest }));
  
        return true;
      } catch (e) {      
        console.log({e});
        
        return false;
      }
    };
  return {
    jobs,
    searchJobText, setSearchJobText,
    getJobsForCustomer,
    getJobsForDiagnosticians,
    getJobsForTechnicians,
    fetchCustomer,
    getProposalsForJob,
    ChangeStatusAndCatagories,
    sendProposal,
    markAsCompleteTechnician,
    getJobHistory,
    acceptProposal,
    markAsCompleteCustomer,
    systemSettings,
    saveSettingsInDb,
    customerJobs,
    setCustomerJobs
  }
}
