import Link from "next/link";
import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import logo from "../../public/images/paleo-logo-art.png";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import router from "next/router";
import { tagAbout, tagContact, tagTerms } from "../../utils/lib/events";
import UseWindowSize from "../../hooks/helpers/useWindowSize";
import Image from "next/image";




export const Footer = () => {
  const { user, dbUser } = useContext(UserContext);
  const [current, setcurrent] = useState("");
  const {windowSize} = UseWindowSize();

  const [toggleDropDown, setToggleDropDown] = useState(false);

  const signInSignUpProfileUpdate = () => {
    if (user === null)
      router.push("sign-in")
  }

  const toggleDropDownMenu = () => {
    if (user !== null) {
      setToggleDropDown(!toggleDropDown);
    }
  }

  const signInSignUp = () => {
    router.push("signup/technician-sign-up")
  }

  return (
    <div className="container-fluid">
      <footer className="footer dark-bg footer-links">
        <div className="container pt-5 pb-4">
          <div className="row">
            <div className={`footer-col ${windowSize?.width > 768 ? "col-6" : "col-12"} col-lg-2`}>
              <div className="me-3">
                <Link href="/" passHref>
                  <a className={`d-flex ${windowSize?.width > 768 ? "align-items-start" : "align-items-center justify-content-center"}`}>
                    <Image src={logo.src} className="img-fluid" width={135} height={120} style={{ objectFit: 'cover' }} alt="Logo" />
                  </a>
                </Link>
              </div>
            </div>
            {/* Service & Repair */}
            {windowSize?.width < 768 && <div className="mt-3"></div>}
            <div className="footer-col col-6 col-lg-2">
              <ul className="col-heading">
                  <Link href={"/garageworks.html"}>
                    <li className="col-heading"><a href={"/garageworks.html"}>Service & Repair</a></li>
                  </Link>
                  <Link href={"/become-technician"}>
                    <li className="col-heading"><a href={"/become-technician"}>We’re hiring! Join our team!</a></li>
                  </Link>
              </ul>
            </div>
            {/* Gas Delivery */}
            <div className="footer-col col-6 col-lg-2">
                <ul className="col-heading">                
                  <Link href={'/carcreditprogram.html'}>
                    <li className="col-heading"><a href={'/carcreditprogram.html'}>Gas Delivery</a></li>
                  </Link>
                </ul>
              <ul className="col-heading">                
                <Link href="/supportedzipcodes.html">
                  <li className="col-heading"><a href="/supportedzipcodes.html">Service Locations</a></li>
                </Link>
              </ul>
            </div>
            {/* Fleet Management */}
            <div className="footer-col col-6 col-lg-2">
              <ul className="col-heading">
              <Link href={'/fleetrepairandmaintenance.html'}>
                <li className="col-heading"><a href={'/fleetrepairandmaintenance.html'}>Fleet Management</a></li>
              </Link>
              {/* <Link href={'/blog.html'}>
                  <li className="col-heading"><a href="/blog.html">Blog Page</a></li>
                </Link> */}
              </ul>
            </div>
            {/* About us */}
            <div className="footer-col col-6 col-lg-2">
              <ul className="col-heading">
                <Link href="/aboutus.html">
                  <li onClick={() => tagAbout()} className="col-heading"><a href="/aboutus.html">About Us</a></li>
                </Link>
                <Link href={'/dealership.html'}>
                  <li className="col-heading"><a href="/dealership.html">Why Paleo</a></li>
                </Link>
                <Link href={'/contactus.html'}>
                  <li className="col-heading"><a href="/contactus.html">Contact Us</a></li>
                </Link>
                <Link href={'/terms.html'}>
                  <li className="col-heading"><a href="/terms.html">Terms & Conditions</a></li>
                </Link>
                
                {/* <Link href={'/press-release.html'}>
                  <li className="mb-2"><a href="/press-release.html">Paleo Car Care launches version 2 of the Mobile App</a></li>
                </Link> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="download-area py-4">
          <div className="container text-center mt-4">
            {/* <div className="section-intro mb-4 single-col-max-width mx-auto white-light">
              <h4 className='mb-1 col-heading'>Paleo Automotive</h4>
            </div> */}
            <ul className="app-stores col-heading list-inline mx-auto  d-inline-block position-relative">
              <p className="mb-0 white-light">2023 © Paleo Car Care   |   <a href="/privacypolicy.html">Privacy Policy</a></p>
              <div className="position-lg-absolute mt-3 mt-lg-0">
                <ul className="social-list col-heading mb-0">
                  <li className="list-inline-item mx-2"><a target="_blank" href="https://www.facebook.com/PaleoCarCare/">
                    <FaFacebook size={18} /></a></li>
                  <li className="list-inline-item mx-2"><a target="_blank" href="https://twitter.com/paleocarcare">
                    <FaTwitter size={18} /></a></li>
                  <li className="list-inline-item mx-2"><a target="_blank" href="https://www.instagram.com/paleocarcare">
                    <FaInstagram size={18} /></a></li>
                </ul>
              </div>
            </ul>
          </div>
        </div>


      </footer>
    </div>
  );
};
