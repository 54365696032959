export interface JobRequest {
  categories: Category[];
  description?: string;
  currentAddress: CustomerAddressType;
  pickYourVehicle: boolean;
  towYourVehicle?: boolean;
  requestVehicleRental?: boolean;
  pickupAddress?: CustomerAddressType;
  pickupDescription?: string;
  dropOffVehicle?: boolean;
  serviceAtHome: boolean;
  serviceDate: string;
  time: string;
  insurenceCoverage: boolean;
  commericalVehicle: boolean;
  ownerCompany?: string;
  images: string[];
  allTechnicians?: boolean;
  technicians?: string[];
  createdAt: string;
  lastModified: string;
  userId: string;
  status: string;
  id: string;
  vehicle: VehiclesType;
  jobTotal?: number;
  assignedTechnician?: string;
  assignedDiagnostician?: string;
  proposal?: ProposalType;
  isTechnicianRated?: boolean;
  customerDetails?: PostedBy;
  completedByTechnician?: boolean;
  completedByCustomer?: boolean;
  technicianCompletionDate?: string;
  customerCompletionDate?: string;
  technicianJobCompletedData?: {
    price: number;
    message: string;
    pdfUrl: PdfUrlData[];
    reasonToUpdatePrice: string;
    outstandingIssueWithCar: string;
  };
  insuranceDetails?: string;
  insurencePolicyNumber?: string;
  totalProposals?: number;
  totalUpdatedProposals?: number;
  diagnosticQuestions?: DiagnosticQuestions[];
  priceSaved?: number;
  estimatedCompletionDate?: string;
  statusUpdated?: boolean;
  workStatus?: string;
  customerRating?: number;
  customerRatingDescription?: string;
  customerOverallRating?: number;
  customerOverallRatingDescription?: string;
  customerSurveyUrl?: string;
  garageId?: number;
  selectedDropOffGarageId?: number;
  showInWaitingRoom?: boolean;
  tekMetric?: {
    id?: number;
    RONumber?: number;
    estimateUrl?: string;
    inspectionUrl?: string;
    invoiceUrl?: string;  
  };
  keyCafe?: {
    assignedToCustomerDropoff?: boolean;
    customerDroppedOff?: boolean;
    assignedToAdminDropoff?: boolean;
    adminPickedup?: boolean;
    assignedToAdminPickup?: boolean;
    adminDroppedoff?: boolean;
    assignedToCustomerPickup?: boolean;
    customerPickedup?: boolean;
  };
  customerCompletionData?: {
    pickup?: boolean;
    dropoff?: boolean;
    dropoffAddress?: CustomerAddressType;
    carCarCredits?: number;
    amountPaid?: number;  
  };
  cancelJobData?: {
    reason: string;
    comment: string;
    cancelledAt: string;
  };
  cancelProposalData?: {
    proposalId: string;
    reason: string;
    comment: string;
    cancelledAt: string;
  }[];
  usePaleoCreditsForThisOrder?: boolean;
  promoCode?: string;
  technicianIdsForEstimate?: string[];
  adminInputForTechnicianEstimate?: {
    message?: string;
  };
  estimatedDealershipRate?: number;
}

export interface PdfUrlData {
  url: string;
  name: string;
}

export interface DiagnosticQuestions {
  text: string;
  id: string;
  categoryId: string;
}

export type PostedBy = {
  name: string;
  techniciansRating: number;
  awardedJobs: number;
  postedJobs: number;
}
export type RatingType = {
  customerId: string;
  rating: number;
  description: string;
  technicianId: string;
  createdAt: string;
  customerName: string;
  id: string;
  jobId: string;
};

export interface ProposalAcceptRequestType {
  lastModified: string;
  status: string;
  proposal: ProposalType;
  assignedTechnician: string;
}

export interface CancelJobRequest {
  id: string;
  reason?: string;
  comment?: string;
}

export interface Category {
  name: string;
  image?: string;
  id: string;
  description: string;
  type?: string;
  main?: string;
  status?: "active" | "inactive";
  lastUpdated?: string;
  lastUpdatedBy?: string;
  deleteDate?: string;
  deletedBy?: string;
}

export enum STATUS {
  Pending = "Order Pending",
  ProposalReceived = "Proposal Received",
  InProgress = "Order In Progress",
  Completed = "Order Completed",
  Cancelled = "Order Cancelled",
  Closed = "Order Closed",
}

export enum NEW_STATUS {
  NewOrder = "New Order",
  AssignDiagnostician = "Assigned To Diagnostic Technician",
  DiagnosticInProgress = "Diagnostic in Progress",
  ReadyForProposal = "Ready for Estimate",
  AwaitingCustomerApproval = "Estimate Provided",
  Customer_Rejected = "Customer Rejected",
  CustomerApproved = "Customer Approved",
  AssignedToTechnician = "Assigned to Technician",
  OrderInProgress = "Order in Progress",
  ReadyForReview = "Ready for Review",
  ServiceCompleted = "Service Completed",
  OrderCompleted = "Order Completed",
  Cancelled = "Order Cancelled"
}
export enum Waiting_Room {
  NewOrder = "New Order",
  CompanyName = "Company Name",
  VehicleName = "Vehicle Name",
  Status = "Status"

}
export interface ProposalType {
  technicianId: string;
  jobId: string;
  customerId: string;
  message: string;
  createdAt: string;
  lastModifed: string;
  proposalId: string;
  status: string;
  price?: number;
  laborByCategories: {
    laborHours?: number;
    laborRate?: number;
    categorId: string;
  }[];
  pdfUrl?: PdfUrlData[];
  expectedCompletionDays?: string;
  startingDayFromNow?: string;
  reasonToUpdatePrice?: string;
  approvedByPaleo?: boolean;
  priceSaved?: number;
}

export interface ProposalAcceptRequestToTechnicianType {
  technicianId?: string;
  jobId?: string;
  customerId?: string;
  price?: string;
  message?: string;
  createdAt?: string;
  lastModifed?: string;
  proposalId?: string;
  status?: string;
}

export enum ProposalStatus {
  submitted = "submitted",
  accepted = "accepted",
  declined = "declined",
  lost = "lost",
}

export type CustomerAddressType = {
  customerAddressId: number;
  addressType: string;
  customerId: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: number;
  zip4: number;
  country: string;
  modifiedBy: string;
  modifiedDateUtc: string;
  latitude: number;
  longitude: number;
  appID: string;
};

export type TechnicianType = {
  userId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  rating?: number;
  totalRatings?: number;
  address: TechnicianAddressType;
  createdAt: string;
  lastModified: string;
  image: string;
  isAdmin?: string;
};

export type TechnicianAddressType = {
  technicianAddressId?: string;
  addressType: string;
  technicianId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  lastModified: string;
  latitude?: number;
  longitude?: number;
  phoneNumber: string;
};

export type VehiclesType = {
  vehicleId: number;
  orderTypeId: number;
  customerId: number;
  model: string;
  make: string;
  modelYear: string;
  license: string;
  licenseState?: string;
  color: string;
  vehicleType: string;
  image: string | null;
  comments: string;
  modifiedBy: string;
  modifiedDateUtc: string;
  isActive: boolean;
  media: { url: string }[];
  vin?: string;
};

export type vehicleMedia = {
  id: number;
  isPrimary: boolean;
  modifiedBy: string;
  modifiedDateUtc: string;
  thumbnailUrl: string;
  type: string;
  url: string;
  vehicleId: number;
};

export type CustomerType = {
  userId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  createdAt: string;
  phoneNumber: string;
  messagingToken?: string;
};

export interface CallCustomerRequest {
  customerToken: string;
  userName: string;
}

export interface MarkAsCompleteRequest {
  price: number;
  message: string;
  pdfUrl: PdfUrlData[];
  id: string;
  completedByTechnician: boolean;
  completedByCustomer: boolean;
  outstandingIssueWithCar: string;
  reasonToUpdatePrice?: string;
}
export interface TechnicianNotes {
  id: string;
  jobId: string;
  createdAt: string;
  notes: string;
  technicianId: string;
  currentStatus?: string;
}
export interface JobHistory {
  operation: string;
  oldData: JobRequest | null;
  currentData: JobRequest | null;
  timeStamp: string;
  eventId: string;
  documentId: string;
  documentName: string;
}
export interface MarkAsCompleteCustomerModel {
  id: string;
  completedByCustomer: boolean;
  customerCompletionDate: string;
  paymentIntentId: string;
  status: string;
  paymentMethodId?: string;
  pickup?: boolean;
  dropoff?: boolean;
  dropoffAddress?: CustomerAddressType;
  carCarCredits?: number;
  amountPaid?: number;
}
export interface UpdateStatusAndCatagory {
  id: string;
  status?: string;
  categories?: {
    name: string;
    image?: string;
    id: string;
    description?: string;
    type?: string;
  }[];
  assignedTechnician?: string;
  completedByTechnician?: boolean;
  commentInternalOnly: string;
  garageId?: number;
}
export interface SystemSettings {
  avgSavedPercentageByCustomer: number;
  workStatus: string[];
}

export interface ServiceOrderSummary {
  id: string;
  customerName: string;
  vehicle: string;
  garageId?: number;
  status: string;
  technicianName?: string;
  lastModified: string;
  createdAt: string;
  history: {
    title: string;
    cardTitle: string;
    cardSubtitle: string;
    cardDetailedText: string;
  }[];
}