import { useEffect, useState } from "react";
import { DbTechnician, DbUser } from "../../models/user";
import { auth, db } from "../../firebase/firebaseutil";
import { query, collection, onSnapshot, where } from "firebase/firestore";

const CUSTOMER = "customer";
const TECHNICIAN = "technician";


export default function useFirebaseUsers(dbTechnician?: DbTechnician) {
  const [dbTechnicians, setDbTechnicians] = useState<DbTechnician[]>([]);
  const [dbCustomers, setDbCustomers] = useState<DbUser[]>([]);
  const [searchTermTechnicians, setSearchTermTechnicians] = useState('');
  const [searchTermCustomers, setSearchTermCustomers] = useState('');


  const appendTechnician = async (add: DbTechnician) => {
    await setDbTechnicians(prev => [...prev.filter(pk => pk.userId !== add.userId), add]);
  };

  const appendCustomer = async (add: DbUser) => {
    if (add?.status != "deleted") {
      await setDbCustomers(prev => [...prev.filter(pk => pk.userId !== add.userId), add]);
    }
  };
  // useEffect(() => {
  //   if (!auth?.currentUser?.uid) {
  //     // setDbTechnicians([]);
  //   }
  // }, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (!dbTechnician?.isAdmin) {
      setDbCustomers([]);
    }
  }, [dbTechnician?.isAdmin]);

  useEffect(() => {
    if (!dbTechnician?.isAdmin) {
      setDbCustomers([]);
    }
  }, [dbTechnician?.isAdmin]);

  useEffect(() => {
    if (!dbTechnician?.isAdmin) return;
    const q = query(collection(db, `${CUSTOMER}`));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendCustomer(change.doc.data() as DbUser);
        } else if (change.type === "modified") {
          appendCustomer(change.doc.data() as DbUser);
        } else if (change.type === "removed") {
          setDbCustomers(prev =>
            prev.filter(p => p.userId !== change.doc.data().id)
          );
        }
      });
    });

    return unsubscribe;
  }, [auth?.currentUser?.uid, dbTechnician?.isAdmin]);

  useEffect(() => {
    // if (!auth?.currentUser?.uid) return;
    const q = query(collection(db, TECHNICIAN), where("isAdmin", "==", false));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendTechnician(change.doc.data() as DbTechnician);
        } else if (change.type === "modified") {
          appendTechnician(change.doc.data() as DbTechnician);
        } else if (change.type === "removed") {
          setDbTechnicians(prev =>
            prev.filter(p => p.userId !== change.doc.data().id)
          );
        }
      });
    });
    //console.log("Data:", dbTechnicians);

    return unsubscribe;
  }, []);
  //}, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (!dbTechnician?.isAdmin) return;
    const q = query(collection(db, `${CUSTOMER}`));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendCustomer(change.doc.data() as DbUser);
        } else if (change.type === "modified") {
          appendCustomer(change.doc.data() as DbUser);
        } else if (change.type === "removed") {
          setDbCustomers(prev =>
            prev.filter(p => p.userId !== change.doc.data().id)
          );
        }
      });
    });
    return unsubscribe;
  }, [auth?.currentUser?.uid]);

  const searchedTechnicians = dbTechnicians.filter((item: DbTechnician) => {
    if (searchTermTechnicians) {
      return (
        `${item?.firstName ?? ""} ${item?.middleName ?? ""} ${item?.lastName ?? ""} ${item?.email ?? ""}`
          ?.toString()
          ?.toLowerCase()
          .indexOf(searchTermTechnicians?.toLowerCase()) !== -1
      );
    }
    return true;
  });


  return {
    dbTechnicians: searchedTechnicians,
    setDbTechnicians,
    searchTermCustomers,
    searchTermTechnicians,
    setSearchTermCustomers,
    setSearchTermTechnicians,
    dbCustomers,
  }
}
