import styled from 'styled-components';

export const FixedFooterWrapper = styled.div`
background:#242424;
// border:1px solid #EAEAEA;
padding:15px 0;
position: fixed;
bottom: 0;
right: 0;
width: 100%;
z-index: 10000;
.logo svg{max-width:80%};
.light-grey{
    color:#C1C0C0 !important;
}
.light-white{
    color:#FCFCFC;
}

.footer-container{
    min-height:200px;
}
.text-lg-left{
    text-align: center;
}
@media(min-width:920px){
    .text-lg-left{
        text-align: left !important;
    }
}
`

export const FloatingFooterWrapper = styled.div`
background:#242424;
// border:1px solid #EAEAEA;
padding:20px 0;
.logo svg{max-width:80%};
.light-grey{
    color:#C1C0C0 !important;
}
hr{
    border-top:1px solid #C1C0C0;
}
.light-white{
    color:#FCFCFC;
}
.footer-container{
    min-height:200px;
}
.text-lg-left{
    text-align: center;
}
@media (min-width:920px){
    .text-lg-left{
        text-align: left !important;
    }
}
`