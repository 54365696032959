import Router from "next/router";
import { Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleChatSelector, toggleChatTopicsSelector } from "../store/selectors/common.selectors";
import { toggleChatAction, toggleChatTopicsAction } from "../store/slices/common.slice";

interface Props {
    showNotification: any;
    setShowNotification: any;
    notification: any;
}

export const NotificationToast = (props: Props) => {

    const {setShowNotification, showNotification, notification} = props;

    const toggleChat = useSelector(toggleChatSelector);
    const toggleChatTopics = useSelector(toggleChatTopicsSelector);
    const dispatch = useDispatch();
    const toggleChatTopicsHandler = () => {
      if (!toggleChatTopics)
        dispatch(toggleChatTopicsAction(!toggleChatTopics));
      if (toggleChat) { dispatch(toggleChatAction(!toggleChat)); }
    };

    const clickNotification = async() => {
        if(notification.title === "Job Created"){
            Router.push("/");
        }else {
            await toggleChatTopicsHandler();
        }
    }
  
    return (
        <Toast 
            onClose={() => setShowNotification(false)} 
            onClick={() => clickNotification()}
            show={showNotification} delay={10000} 
            autohide animation 
            style={{
            position: 'absolute',
            top: 20,
            right: 20,
            minWidth: 200
            }}>
            <Toast.Header>
            <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
            />
            <strong className="mr-auto">{notification.title}</strong>
            <small>just now</small>
            </Toast.Header>
            <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
    );
}