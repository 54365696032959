import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { commonSlice } from "./common.slice";



const reducers = combineReducers({
  common: commonSlice.reducer,
});


export default configureStore({
  reducer: reducers,
});
