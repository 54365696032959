import React, { createContext, useState } from "react";
import useChatTopics from "../hooks/chat/useChatTopics";
import { ChatTopic } from "../models/chat";
import { JobRequest } from "../models/jobs";

interface IChatContext {
  selectedJob?: JobRequest;
  selectedTopic?: ChatTopic;
  chatTopics: ChatTopic[];
  searchedChatTopics: ChatTopic[];
  searchedChatTopicText: string;
  unreadTopicsCount: number;
  setSearchedChatTopicText: (s: string) => void;
  setSelectedJob: (product: JobRequest | undefined) => void;
  setSelectedTopic: (topic: ChatTopic | undefined) => void;
}

const initProps: IChatContext = {
  chatTopics: [],
  searchedChatTopics: [],
  searchedChatTopicText: "",
  unreadTopicsCount: 0,
  setSearchedChatTopicText: (s: string) => { },
  setSelectedJob: (product: JobRequest | undefined) => { },
  setSelectedTopic: (topic: ChatTopic | undefined) => { },
};

export const ChatContext = createContext<IChatContext>(initProps);

export const ChatProvider = ({ children }: { children: any }) => {
  const { chatTopics, unreadTopicsCount } = useChatTopics();
  const [selectedTopic, setSelectedTopic] = useState<ChatTopic>();
  const [selectedJob, setSelectedJob] = useState<JobRequest>();
  const [searchedChatTopicText, setSearchedChatTopicText] = useState("");

  const searchedChatTopics = chatTopics?.filter((item: ChatTopic) => {
    if (searchedChatTopicText) {
      return (
        `${item?.lastMessage}${item?.jobId}${item?.lastMessageFrom}${item?.partyNames?.[0]}${item?.messageCount}${item?.unreadMessagesCount}`
          ?.toString()
          ?.toLowerCase()
          .indexOf(searchedChatTopicText?.toLowerCase()) !== -1
      );
    }
    return true;
  });

  return (
    <ChatContext.Provider
      value={{
        selectedJob,
        setSelectedJob,
        selectedTopic,
        setSelectedTopic,
        chatTopics,
        searchedChatTopics,
        unreadTopicsCount,
        searchedChatTopicText, setSearchedChatTopicText
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
