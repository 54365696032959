import * as gtag from './analytics';
import { CustomEventParams } from './analytics-model';

export const tagReadFAQ = (question: string) => {
  gtag.customEvent('read_faq', { item_name: question })
}

export const tagSignUp = () => gtag.event("sign_up", {});

export const tagLogin = (data?:any) => gtag.event("login", data);

export const tagLink =(data?:CustomEventParams) => gtag.customEvent("link", data);

export const tagButton =(data?:CustomEventParams) => gtag.customEvent("ButtonPress", data);

export const tagEmail= (data?:CustomEventParams) => gtag.customEvent("Email", data);


export const tagTerms = () => {
  gtag.customEvent('terms', { })
}

export const tagAbout = () => {
  gtag.customEvent('about', { })
}

export const tagContact = () => {
  gtag.customEvent('contact', { })
}

export const tagDownloadPage = () => {
  gtag.customEvent('download_app',{})
}

export const tagDownloadApp = (data?:CustomEventParams) => {
  gtag.customEvent('download_app',data)
}

