import { useContext, useEffect, useState } from "react";
import "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  doc,
  query,
  writeBatch,
  collection,
  onSnapshot,
  where,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { auth, db, functions } from "../../firebaseutil";
import {JobRequest, NEW_STATUS, ProposalType, STATUS } from "../../models/jobs";
import { UserContext } from "../../contexts/UserContext";
import { httpsCallable } from "firebase/functions";

const JOB = "job";
const TECHNICIANS = "technician";
const RATING = "rating";
const PROPOSAL = "proposal";
const UPDATED_PROPOSAL = "updated-proposal";

const RequestType = {
  jobCompletedTechnician: "job-completed-technician",
  sendProposal: "send-proposal",
  sendUpdatedProposal: "send-updated-proposal",
}

export default function useProposals() {
  const [jobs, setJobs] = useState<JobRequest[]>([]);
  const [searchJobText, setSearchJobText] = useState("");
  const [currentJob, setCurrentJob] = useState<JobRequest>();
  const {dbUser, user, dbTechnician} = useContext(UserContext);
  const [proposals, setProposals] = useState<ProposalType[]>([]);

  const appendJob = (add: JobRequest) => {
    setJobs(prev => [...prev.filter(pk => pk.id !== add.id), add]);
  };

  const appendProposal = (add: ProposalType) => {
    setProposals(prev => [...prev.filter(pk => pk.proposalId !== add.proposalId), add]);
  };

  useEffect(() => {
    if (!auth?.currentUser?.uid) {
      setJobs([]);
      setProposals([]);
    }
  }, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (!auth?.currentUser?.uid && !!!dbTechnician?.userId) {
      return;
    }
    (async () => {
      try {
        const batch = writeBatch(db);
        const topicRef = doc(db, TECHNICIANS, `${auth?.currentUser?.uid}`);
        const updatedTopic = {
          lastUpdated: new Date().getTime(),
        };

        batch.set(topicRef, updatedTopic, { merge: true });

        await batch.commit();
      } catch (e) {
        console.log({ firebaseError: e });
      }
    })();
  }, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    const q = query(collection(db, `${JOB}`), where("technicians", "array-contains", auth?.currentUser?.uid));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendJob(change.doc.data() as JobRequest);
        } else if (change.type === "modified") {
          appendJob(change.doc.data() as JobRequest);
        } else if (change.type === "removed") {
          setJobs(prev => prev.filter(p => p.id !== change.doc.data().id));
        }
      });
    });
    return unsubscribe;
  }, [dbUser?.userId]);

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    const q = query(collection(db, `${JOB}`), where("assignedTechnician", "==", auth?.currentUser?.uid));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendJob(change.doc.data() as JobRequest);
        } else if (change.type === "modified") {
          appendJob(change.doc.data() as JobRequest);
        } else if (change.type === "removed") {
          setJobs(prev => prev.filter(p => p.id !== change.doc.data().id));
        }
      });
    });
    return unsubscribe;
  }, [dbUser?.userId]);

  const sendProposal = async(data: ProposalType) => {
    try {
      const callable = await httpsCallable(functions, "jobCall");
      (await callable({requestType: RequestType.sendProposal, input: data }));  
      return true;
    } catch (e) {
      return false;
    }
  }

  const sendUpdatedProposal = async(data: ProposalType) => {
    try {
      const callable = await httpsCallable(functions, "jobCall");
      (await callable({requestType: RequestType.sendUpdatedProposal, input: data }));  
      return true;
    } catch (e) {
      return false;
    }
  }

  let optionsList = jobs?.sort(
    (a, b) => new Date(b?.createdAt).valueOf() - new Date(a?.createdAt).valueOf()
  );

  const searchedJobs = optionsList.filter((item: JobRequest) => {
    if (searchJobText) {
      return (
        `${item?.categories?.map(i => i?.name)} ${item?.description} ${item?.currentAddress?.addressLine1} ${item?.currentAddress?.addressLine2} ${item?.currentAddress?.city}${item?.currentAddress?.country}${item?.currentAddress?.state}${item?.currentAddress?.zipCode} ${item?.vehicle?.make} ${
          item?.vehicle?.model
        } ${item?.vehicle?.modelYear} ${
          item?.vehicle?.license
        } ${item?.vehicle?.color}`
          ?.toString()
          ?.toLowerCase()
          .indexOf(searchJobText?.toLowerCase()) !== -1
      );
    }
    return true;
  });

  const markAsCompleteTechnician = async(data: any) => {
    try{ 
      const callable = await httpsCallable(functions, "jobCall");
      (await callable({requestType: RequestType.jobCompletedTechnician, input: data }));
      return true;  
    }catch(e) {      
      return false;
    }
  }

  return {
    jobs,
    searchJobText,
    setSearchJobText,
    searchedJobs,
    setCurrentJob,
    currentJob,
    sendProposal,
    proposals,
    sendUpdatedProposal,
    markAsCompleteTechnician,
  };
}
