import { useContext, useEffect, useState } from "react";
import "firebase/auth";
import {
  query,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { auth, db } from "../../firebaseutil";
import { RatingType } from "../../models/jobs";
import { UserContext } from "../../contexts/UserContext";

const TECHNICIANS = "technician";
const RATING = "rating";

export default function useRatings() {
  const [ratings, setRatings] = useState<RatingType[]>([]);
  const {dbUser} = useContext(UserContext);

  const appendRating = (add: RatingType) => {
    setRatings(prev => [...prev.filter(pk => pk.id !== add.id), add]);
  };


  useEffect(() => {
    if (!auth?.currentUser?.uid) {
      setRatings([]);
    }
  }, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    const q = query(collection(db, `${TECHNICIANS}/${auth?.currentUser?.uid}/${RATING}`));
    const unsubscribe = onSnapshot(q, snapshot => { 
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendRating(change.doc.data() as RatingType);
        } else if (change.type === "modified") {
          appendRating(change.doc.data() as RatingType);
        } else if (change.type === "removed") {
          setRatings(prev => prev.filter(p => p.id !== change.doc.data().id));
        }
      });
    });
    return unsubscribe;
  }, [dbUser?.userId]);


  let optionsList = ratings?.sort(
    (a, b) => new Date(b?.createdAt).valueOf() - new Date(a?.createdAt).valueOf()
  );
  
  return {
    ratings: optionsList
  };
}
