import { Routes } from "../../config/routes";
import { auth, db} from "../../firebase/firebaseutil";
import {onAuthStateChanged, signInWithEmailAndPassword, signOut, User, sendPasswordResetEmail ,createUserWithEmailAndPassword} from "firebase/auth";
import Cookies from "js-cookie";
import {useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRouter } from 'next/router';
import { AUTH_CRED } from "../../utils/constants";
import { doc, setDoc } from "firebase/firestore";
import { CUSTOMER, TECHNICIAN } from "./useFirebase";

export const useFirebaseLogin = () => {

    const [user, setUser] = useState<User>();
    const router = useRouter();

    useEffect(() => {
        const authObserverUnsubscribe = onAuthStateChanged(auth,
          async (firebaseUser) => {
            if (!!firebaseUser) {
              setUser(firebaseUser);
              Cookies.set("USER_ID",firebaseUser.uid);
            } else {
              setUser(undefined);
              Cookies.remove('USER_ID');
              Cookies.remove(AUTH_CRED);
            }
          },
          (error) => {
            console.log({error});
          }
        );
        return () => {
          // ...
          authObserverUnsubscribe();
        };
      }, []);
        
 const emailSignUp=async(email: string, password: string): Promise<any> => {
  try{
    const result = await createUserWithEmailAndPassword(auth, email, password);
    if(result.user){
      setUser(result?.user);
      Cookies.set("USER_ID",result?.user?.uid);
      return result?.user;
    }else if(!result?.user){
      // return "failed to login.";
      console.log(result,"not found");

    }    
  }catch(e) {
    console.log(e);
    let error="OOps something wents wrong!";
    if(e=="FirebaseError: Firebase: Error (auth/email-already-in-use)."){
      error="User already exist"
    }
    else if(e=="FirebaseError: Firebase: Password should be at least 6 characters (auth/weak-password)."){
      error="Password should be at least 6 characters"
    }
    return error;
  }
}

    const emailLogin = async(email: string, password: string): Promise<any> => {
      try{
        const result = await signInWithEmailAndPassword(auth, email, password);
        if(result?.user?.uid){
          setUser(result?.user);
          Cookies.set("USER_ID",result?.user?.uid);
          return result?.user;
        }else if(!result?.user){
          return "failed to login.";
        }    
      }catch(e) {
        console.log(e);
        return 'Invalid Password';
      }
    }

    const forgotPassword = async(email: string): Promise<any> => {
      try{
        const result = await sendPasswordResetEmail(auth, email)
        return true;
      }catch(e) {
        return e;
      }
    }

  const emailLogout = async() => {
    await signOut(auth).then(() => {
    // Sign-out successful.
      setUser(undefined);    
      // setDbCustomers([]);
      // setDbTechnician(undefined);
      // setDbTechnicians([]);
      // setDbUser(undefined);
      Cookies.remove(AUTH_CRED);
      Cookies.remove('USER_ID');
      router.replace(Routes.login);
      toast.success('Logout Successfully.');
    }).catch((error) => {
        // An error happened.
    });
  }

    return {
        emailLogin,
        user,
        emailLogout,
        forgotPassword,
        emailSignUp
    }
}