import { useContext, useEffect, useState } from "react";
import "firebase/auth";
import {
  query,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { auth, db } from "../../firebase/firebaseutil";
import { JobRequest, ProposalType, TechnicianNotes } from "../../models/jobs";
import { UserContext } from "../../contexts/UserContext";

const JOB = "job";
export const PROPOSAL = "proposal";
const TECHNICIAN_NOTES = "technician-notes";


export default function useJobProposals(jobId: string) {
  
  const {user} = useContext(UserContext);
  const [proposals, setProposals] = useState<ProposalType[]>([]);
  const [technicianNotes, setTechnicianNotes] = useState<TechnicianNotes[]>([]);

  const appendProposal = (add: ProposalType) => {
    setProposals(prev => [...prev.filter(pk => pk.proposalId !== add.proposalId), add]);
  };

  const appendTechnicianNotes = (add: TechnicianNotes) => {
    setTechnicianNotes(prev => [...prev.filter(pk => pk.id !== add.id), add]);
  };

  useEffect(() => {
    if (!user?.uid) {
      setProposals([]);
    }
  }, [user?.uid]);

  useEffect(() => {
    if (!user?.uid) return;
    const q = query(collection(db, `${JOB}/${jobId}/${PROPOSAL}`));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendProposal(change.doc.data() as ProposalType);
        } else if (change.type === "modified") {
          appendProposal(change.doc.data() as ProposalType);
        } else if (change.type === "removed") {
          setProposals(prev => prev.filter(p => p.proposalId !== change.doc.data().id));
        }
      });
    });
    return unsubscribe;
  }, [user?.uid]);

  useEffect(() => {
    if (!user?.uid) return;
    const q = query(collection(db, `${JOB}/${jobId}/${TECHNICIAN_NOTES}`));
    const unsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          appendTechnicianNotes(change.doc.data() as TechnicianNotes);
        } else if (change.type === "modified") {
          appendTechnicianNotes(change.doc.data() as TechnicianNotes);
        } else if (change.type === "removed") {
          setTechnicianNotes(prev => prev.filter(p => p.id !== change.doc.data().id));
        }
      });
    });
    return unsubscribe;
  }, [user?.uid]);


  return {
    proposals,
    technicianNotes,
  };
}
