import { useContext } from "react";
import Link from "next/link";
import React from "react";
import { UserContext } from "../../../contexts/UserContext";
import { toggleChatSelector, toggleChatTopicsSelector } from "../../../store/selectors/common.selectors";
import { useDispatch, useSelector } from "react-redux";
import { toggleChatAction, toggleChatTopicsAction } from "../../../store/slices/common.slice";
import { GetBold } from "../../../styles/checkout-page-style";
import { DbUser } from "../../../models/user";
import { auth } from "../../../firebaseutil";

export const DropdownMenu = ({ signInSignUp }: { signInSignUp: (isSigningUp: boolean) => void }, {
  user }: { user: DbUser; }) => {
  const { emailLogout, dbUser } = useContext(UserContext);
  const toggleChat = useSelector(toggleChatSelector);
  const toggleChatTopics = useSelector(toggleChatTopicsSelector);
  const dispatch = useDispatch();
  const toggleChatTopicsHandler = (e?: any) => {
    if (!toggleChatTopics)
      dispatch(toggleChatTopicsAction(!toggleChatTopics));
    if (toggleChat) { dispatch(toggleChatAction(!toggleChat)); }
  };

  const signOut = async () => {
    await emailLogout();
  }

  return (
    <ul className="list-unstyled">
      {dbUser?.userId && <li>
        <p style={GetBold}>{dbUser?.email ?? dbUser?.phoneNumber} </p>
      </li>}
      {/* {dbUser?.userId &&
        <li>
          <Link href="/t/profile">
            <a className="font-normal">My Profile</a>
          </Link>
        </li>
      } */}
      {/* {dbUser?.userId &&
        <li>
          <Link href="/my-proposals">
            <a className="font-normal">My Proposals</a>
          </Link>
        </li>
      } */}
      {/* {dbUser?.userId &&
        <li>
          <Link href="/t/ratings">
            <a className="font-normal">My Ratings</a>
          </Link>
        </li>
      } */}
      {/* {dbUser?.userId &&
        <li>
          <a className="font-normal" onClick={(e) => toggleChatTopicsHandler(e)}>My Conversations</a>
        </li>
      } */}
      {!auth?.currentUser?.uid &&
        <li>
          <Link href="/signup/technician-sign-up" passHref>
            <a className="font-normal" onClick={() => signInSignUp(true)}>Sign up</a>``
          </Link>
        </li>
      }
      {auth?.currentUser?.uid &&
        <li>
          <Link href="/sign-in">
            <a className="font-normal" onClick={signOut}>Sign Out</a>
          </Link>
        </li>
      }
    </ul>
  );
};
