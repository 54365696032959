import { onSnapshot, query, collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth, db } from "../../firebaseutil";
import { Category } from "../../models/jobs";

const CATEGORIES = "job-categories";

export const useCategories = () => {

    const [categories, setCategories] = useState<Category[]>([]);

    const appendCategory = async (add: Category) => {
        await setCategories(prev => [...prev.filter(pk => pk.id !== add.id), add]);
      };
    
      useEffect(() => {
        if (!auth?.currentUser?.uid) {
          setCategories([]);
        }
      }, [auth?.currentUser?.uid]);
        
      useEffect(() => {
        const q = query(collection(db, `${CATEGORIES}`));
        const unsubscribe = onSnapshot(q, snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              appendCategory(change.doc.data() as Category);
            } else if (change.type === "modified") {
              appendCategory(change.doc.data() as Category);
            } else if (change.type === "removed") {
              setCategories(prev =>
                prev.filter(p => p.id !== change.doc.data().id)
              );
            }
          });
        });
        return unsubscribe;
      }, []);

      return {
        categories
      }

}