import { useContext, useEffect, useState } from "react";
import { Advertisement } from "../../models/user";
import { db } from "../../firebaseutil";
import { collection, onSnapshot, query, where } from "firebase/firestore";

export function useAdvertisement() {

    const [ads, setAds] = useState<Advertisement[]>([]);

    const appendAd = (add: Advertisement) => {
        setAds(prev => [...prev.filter(pk => pk.id !== add.id), add]);
    };

    useEffect(() => {
    const q = query(collection(db, "advertisement"), where("active", "==", true), where("platform", "==", "customer_web"));
    const unsubscribe = onSnapshot(q, snapshot => {
        snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
            appendAd(change.doc.data() as Advertisement);
        } else if (change.type === "modified") {
            appendAd(change.doc.data() as Advertisement);
        } else if (change.type === "removed") {
            setAds(prev => prev.filter(p => p.id !== change.doc.data().id));
        }
        });
    });
    return unsubscribe;
    }, []);    
    
    return {
        ads,
    }
}