import styled from "styled-components";
export const DropdownMenuWrapper = styled.div`
&:before{content: '';
position: absolute;
right: 16px;
top: -11px;
width: 0;
height: 0;
border-left: 12px solid transparent;
border-right: 12px solid transparent;
border-bottom: 13px solid #fff;
clear: both;
@media(max-width:991px){
 right:4px}
}
  position: absolute;
  background: #fff;
  top:68px;
  z-index: 13;
  right: 10px;
  ul {
    box-shadow: 0px 3px 6px #00000052;
    padding:20px 35px;
    margin: 0;
    min-width: 100px;
    a {
      padding: 0;
      color: #656565;
      font-size: 0.9rem;
      font-weight: 600;
    }
    li {
      padding-bottom: 5px;

      ul {
        box-shadow: none;
        a{ font-weight: 400;}
      }
    }
  }
  .font-normal{font-weight:400}
`;
