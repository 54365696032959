export const LIMIT = 10;
export const SUPER_ADMIN = 'super_admin';
export const STORE_OWNER = 'store_owner';
export const STAFF = 'staff';
export const TOKEN = 'token';
export const PERMISSIONS = 'permissions';
export const AUTH_CRED = 'AUTH_CRED';
export const CART_KEY = 'pick-cart';
export const CHECKOUT = 'pickbazar-checkout';
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const ACCEPTED_FILE_TYPES = "image/*,application/pdf,application/zip,application/vnd.rar,application/epub+zip,.psd";
export const timeOut = 300000; /* ms, *0 - no timeout */
export const AppID = "customerapp";
export const baseURL =
  "https://paleoservices20210103010912-v2prod.azurewebsites.net/api";
  export const ApiUrl = {
    GetCustomerDetailsByPhone: "/Customers/GetCustomerDetailsByPhone",
    GetLoggedInCustomer: "/Customers/GetLoggedInCustomer",
    SendCustomerOTP: "/CustomerOTPs/SendCustomerOTP",
    ValidateCustomerOTP: "/CustomerOTPs/ValidateCustomerOTP",
    OrderTypes: "/OrderTypes",
    PlaceOrder: "/OrderMasters/PlaceOrder",
    GetPreOrderData: "/Customers/GetPreOrderData",
    GetCustomerProfile: "/Customers/GetCustomerProfile",
    GetRewardsSummary: "/Customers/GetRewardsSummary",
    GetPromoCode: "/Customers/GetPromoCode",
    SavePromoCode: "/Customers/SavePromoCode",
    DeleteCustomerPromoCode: "/Customers/DeleteCustomerPromoCode",
    CancelOrder: "/OrderMasters/CancelOrder",
    AddVehicle: "/Customers/AddVehicle",
    UpdateVehicle: "/Customers/UpdateVehicle",
    AddVehicleImage: "/Customers/UpsertVehicleMedia",
    GetImageByVehicleId: "/Vehicles/GetImageByVehicleId",
    Vehicles: "/Vehicles",
    AddNewAddress: "/Customers/AddNewAddress",
    DeleteAddress: "/Customers/DeleteAddress",
    GetEphemeralKey: "/CustomerStripes/GetEphemeralKey",
    GetPaymentMethods: "/CustomerStripes/GetPaymentMethods",
    AddPaymentMethod: "/CustomerStripes/AddPaymentMethod",
    RemovePaymentMethod: "/CustomerStripes/RemovePaymentMethod",
    GetWeeklyScheduleByCustomer:
      "/CustomerWeeklySchedule/GetWeeklyScheduleByCustomer",
    SaveWeeklySchedule: "/CustomerWeeklySchedule/SaveWeeklySchedule",
    ToggleWeeklyDelivery: "/CustomerWeeklySchedule/ToggleWeeklyDelivery",
    GetServicableZips: "/Customers/GetServicableZips",
    GetPromoCodes: "/Customers/GetPromoCodes",
    RegisterDevice: "/Devices/RegisterDevice",
    UpsertCustomer: "/Customers/UpsertCustomer",
    UpsertShopAppointment: "/ShopOrders/UpsertShopAppointment",
    GetShopAppoinment: "/ShopOrders/GetAppointments",
    GetWeeklyRoutes: "/StandardRoute/GetAllRoutes",
    getWeeklyRouteByCustomer: "/StandardRoute",
    shopCheckin: "/ShopOrders/ShopCheckin",
    UpsertShopAppointmentMedia: "/ShopOrders/UpsertShopAppointmentMedia",
    deleteShopAppoinment: "/ShopOrders/DeleteShopAppointment",
    getShopCannedJobs: "/ShopOrders/GetShopCannedJobs",
    ordersByCustomer: "/customers/",
    servicesofOneOrder: "/orders/",
    PaymentIntents: "/v1/payment_intents",
    PaymentCharge: "/v1/charges",
    stripeCustomer: "/v1/customers",
    SaveUserMembership: "/Customers/AddCustomerMembership",
    GetMembershipLevels: "/Customers/GetMembershipLevels",
  };

  export const paleoConfig = {
    gtmId: "G-MLMTL6RJNS",
  }

  export const websiteBaseUrl = "https://www.paleocarcare.com";

  export const PrimaryColor = "#f41e14";
export const PrimeRed = "#f41e14";
export const PrimeTransparent = "transparent";

export const PrimeGreen = "#99CC99";

export const PrimeBlack = "#242424";

export const PrimeWhite = "#FFFFFF";

export const PrimeGrey = "#DEDEDE";

export const DarkGrey = "#707070";

export const PrimeBlue = "#51A0D5";
export const AccentColor = "#F41E14";

const TintColorLight = PrimaryColor;

const TintColorDark = "#fff";

export const Colors = {
  light: {
    text: "#000",
    background: "#fff",
    tint: TintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: TintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: TintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: TintColorDark,
  },
};

export const loaderColor = "#0000ff";

export const AddressLinkColor = "#1e1e1e";

export const BtnListEmpty = "#ebd353";

export const BtnListAvailable = "#90ee90";

export const BtnGreen = "#1a781a";

export const BtnListAvailableLight = "#88c588";

export const BtnListDefault = "#ffffff";

export const BtnStatusAction = "#f08080";

export const BtnBlack = "#000";

export const RadioColor = "#2e5e37";

export const InputsHeight = 50;

export const InputsBorderColor = "#707070";

export const CommonBorderRadius = 4;
