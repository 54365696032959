import { useEffect, useState } from "react";
import "firebase/auth";
import { signInWithRedirect, signInWithCredential, onAuthStateChanged, User, getRedirectResult, getAdditionalUserInfo, updateProfile, updateEmail, sendEmailVerification, signInAnonymously } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import {  httpsCallable } from '@firebase/functions';
import { DbTechnician, DbUser } from '../../models/user';
import { getFirestore, onSnapshot } from '@firebase/firestore';
import { doc, getDoc, setDoc } from 'firebase/firestore';
// import * as Sentry from "@sentry/nextjs";
import { db, auth, functions } from '../../firebase/firebaseutil';
import {deviceDetect} from "react-device-detect";
import { getMessaging, getToken } from "firebase/messaging";
import localforage from "localforage";
import { PublicProfileByHandle } from "../../models/user";
import Cookies from "js-cookie";

const provider = new GoogleAuthProvider();

export const CUSTOMER = "customer";
export const TECHNICIAN = "technician";

enum RequestType {
  signedIn = "signedIn",
  userExists = "userExists",
  technicianExists = "technicianExists",
  getPublicProfile = "getPublicProfile",
}

export default function useFirebase() {
  const [user, setUser] = useState<User | null>(null);
  const [dbUser, setDbUser] = useState<DbUser>();
  const [dbTechnician, setDbTechnician] = useState<DbTechnician>();
  const [userToken, setUserToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [signInSignUpInProgress, setSignInSignUpInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);

  useEffect(() => {
    if(!auth?.currentUser?.uid){
      setDbTechnician(null);
      setUser(null);
      setTokenFound(false);
      setUser(null);
      setIsAuthenticated(false);
      setUserToken(null);
    }
  }, [auth?.currentUser?.uid])

  useEffect(() => {
    const authObserverUnsubscribe = onAuthStateChanged(auth,
      async (firebaseUser) => {
        if (!!firebaseUser) {
          const token = await firebaseUser.getIdToken();
          setUserToken(token);
          setIsAuthenticated(true);
          setUser(firebaseUser);
          Cookies.set("USER_ID",firebaseUser.uid);
          // Sentry.setUser({ id: firebaseUser.uid });
          const userCredential = await getRedirectResult(auth);
          if (userCredential) {
            const additionalInfo = getAdditionalUserInfo(userCredential);
            if (additionalInfo?.isNewUser) {
              setIsNewUser(true);
              setSignInSignUpInProgress(true);
            } else {
              setIsNewUser(false);
            }
          } else {
            setIsNewUser(false);
          }
          if(!!dbTechnician?.userId){
            await recordSignedIn(firebaseUser.uid);
          }
          // if(window)
          //   window.user_id = firebaseUser.uid;
        } else {
          //user not here, empty token?
          setUserToken(null);
          setIsAuthenticated(false);
          setUser(null);
          Cookies.remove('USER_ID');
        }
      },
      (error) => {
        console.log("in useFirebase, auth error", error);
      }
    );
    return () => {
      // ...
      authObserverUnsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!user?.uid) return;

    const unsubscribe = onSnapshot(doc(db, CUSTOMER, user.uid), (snapshot) => {
      const userRecord = snapshot.data();
      setDbUser(userRecord as DbUser);
    });
    return unsubscribe;
  }, [user]);

  useEffect(() => {
    if (!user?.uid) return;

    const unsubscribe = onSnapshot(doc(db, TECHNICIAN, user.uid), (snapshot) => {
      const userRecord = snapshot.data();
      setDbTechnician(userRecord as DbTechnician);
    });
    return unsubscribe;
  }, [user]);


  const markSignUpComplete = () => {
    setSignInSignUpInProgress(false);
  }

  const recordSignedIn = async (uid: string | undefined) => {
    const signedIn = httpsCallable(functions, 'userCall');
    let deviceData: any = null;
    if(typeof window !== undefined)
      deviceData = deviceDetect(window.navigator.userAgent);
    await signedIn({requestType: RequestType.signedIn, input: {uid, deviceData} });
  }

  const userExists = async (phoneNumber: string) => {
    const checkUser = httpsCallable(functions, 'userCall');
    const result = (await checkUser({requestType: RequestType.userExists, input: {phoneNumber} })).data as {userFound: boolean};
    return result.userFound;
  }

  const technicianExists = async (phoneNumber: string) => {
    const checkUser = httpsCallable(functions, 'userCall');
    const result = (await checkUser({requestType: RequestType.technicianExists, input: {phoneNumber} })).data as {userFound: boolean};
    return result.userFound;
  }

  const updateUserEmail = async (email: string): Promise<string> => {
    if (user && email) {
      try {
        await updateEmail(user, email);
        await sendEmailVerification(user, {
          url: 'https://www.fleato.com/my-account',
          iOS: {
            bundleId: 'com.fleato.marketplace'
          },
          android: {
            packageName: 'com.fleato.marketplace',
            installApp: true,
            minimumVersion: '12'
          },
          handleCodeInApp: true
        });
      } catch (err: any) {
        if (err.toString().includes("auth/requires-recent-login"))
          return "For your safely, we require you to login once again to change the email address";
      }
      return "";
    } else {
      return "user not logged in";
    }
  }

  const fetchDbUser = async(uid: string): Promise<DbUser | undefined> => {
    const ref = doc(db, `${CUSTOMER}/${uid}`);
    const dbUser = (await getDoc(ref)).data() as DbUser;
    return dbUser;
  }

  const fetchDbTechnician = async(uid: string): Promise<DbTechnician | undefined> => {
    const ref = doc(db, `${TECHNICIAN}/${uid}`);
    const dbUser = (await getDoc(ref)).data() as DbTechnician;
    return dbUser;
  }

  const [tokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    if(dbUser?.userId && !tokenFound) {
      (async() => {
        await getMessagingToken();
      })()
    }else {
    }
  }, [dbUser, tokenFound])

  const getMessagingToken = async() => {

    const tokenInLocalForage = await localforage.getItem("fcm_token");

    if (tokenInLocalForage !== null) {
      setTokenFound(true);
      const user1 = {
        userId: dbUser?.userId,
        messagingToken: tokenInLocalForage,
        lastUpdated: new Date().getTime(),
        isActive: true,
      };
      if(!!dbTechnician?.userId){
        await setDoc(doc(db, TECHNICIAN, `${dbUser?.userId}`), user1, { merge: true });  
      }
      if(!!dbUser?.userId)  {
        await setDoc(doc(db, CUSTOMER, `${dbUser?.userId}`), user1, { merge: true });  
      }
    }else{
      const status = await Notification.requestPermission();
      
      if (status && status === "granted") {
        const messaging = getMessaging();        
        await getToken(messaging, { vapidKey: 'BHOMiGFeyiMRo-PvG2fGxwWcVAOHiZ6sBiTIH491lwB_9EW-Onxn05yXRv3wrS6W_cbFA8SMuerJ26Fid5TC7zY' }).then(async(currentToken) => {
          if (currentToken) {            
            localforage.setItem("fcm_token", currentToken);
            setTokenFound(true);
            const user1 = {
              userId: dbUser?.userId,
              messagingToken: currentToken,
              lastUpdated: new Date().getTime(),
              isActive: true,
            };
            if(!!dbTechnician?.userId){
              await setDoc(doc(db, TECHNICIAN, `${dbUser?.userId}`), user1, { merge: true });  
            }
            if(!!dbUser?.userId)  {
              await setDoc(doc(db, CUSTOMER, `${dbUser?.userId}`), user1, { merge: true });  
            }
          } else {
            setTokenFound(false);
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          getMessagingToken();
          setTokenFound(false);
        }); 
      }
    } 
  }

  const fetchPublicProfileByHandle = async (handle?: string): Promise<PublicProfileByHandle> => {
    if(!handle)
        return {handle: ""};
    const publicProfile = httpsCallable(functions, 'userCall');
    const userProfile = (await publicProfile({requestType: RequestType.getPublicProfile, input: {handle} })).data as PublicProfileByHandle;
    return userProfile;
  }


  return {
    user, dbUser, userToken, isAuthenticated, isNewUser, signInSignUpInProgress, isLoading,
    updateUserEmail, markSignUpComplete, fetchDbUser, userExists, setDbTechnician, setDbUser,
    setIsSigningUp, isSigningUp, fetchDbTechnician, dbTechnician, fetchPublicProfileByHandle,
    technicianExists
  }
}
