import React, { createContext } from "react";
import { boolean } from "yup";
import { useCategories } from "../hooks/jobs/useCategories";
import useProposals from "../hooks/jobs/useProposals";
import useRating from "../hooks/jobs/useRating";
import useJobs from "../hooks/jobs/useJobs";
import { Category, CustomerType, JobRequest, ProposalType, RatingType, UpdateStatusAndCatagory } from "../models/jobs";
import { useGarages } from "../hooks/jobs/useGarages";
import { AzureGarage, AzureServicableArea, AzureServicableZipCodes, Garage } from "../models/garage";
import { useAdvertisement } from "../hooks/user/useAdvertisement";
import { Advertisement } from "../models/user";

interface IJobContext {
  jobs: JobRequest[];
  globalJobs: JobRequest[];
  searchJobText: string;
  searchedJobs: JobRequest[];
  currentJob: JobRequest | undefined;
  categories: Category[];
  proposals: ProposalType[];
  customerJobs: JobRequest[];
  setSearchJobText: (text: string) => void;
  setCurrentJob: (data: JobRequest) => void;
  sendProposal: (data: ProposalType) => Promise<boolean>;
  sendUpdatedProposal: (data: ProposalType) => Promise<boolean>;
  ratings: RatingType[];
  markAsCompleteTechnician: (data: any) => Promise<boolean>;
  ChangeStatusAndCatagories: (data: any) => Promise<boolean>;
  fetchCustomer: (id: string) => Promise<CustomerType>;
  getJobsForCustomer: (id: string) => Promise<JobRequest[]>;
  garages: Garage[];
  serviceZipCodes: AzureServicableZipCodes[];
  ads: Advertisement[];
  azureGarages: AzureGarage[]; 
  servicableAreas: AzureServicableArea[];
}

const initProps: IJobContext = {
  jobs: [],
  globalJobs: [],
  categories: [],
  searchJobText: "",
  searchedJobs: [],
  currentJob: undefined,
  proposals: [],
  ratings: [],
  customerJobs: [],
  setSearchJobText: (text: string) => undefined,
  setCurrentJob: (data: JobRequest) => undefined,
  sendProposal: (data: ProposalType) => { return new Promise((resolve, reject) => { resolve(true); }) },
  sendUpdatedProposal: (data: ProposalType) => { return new Promise((resolve, reject) => { resolve(true); }) },
  markAsCompleteTechnician: (data: any) => { return new Promise((resolve, reject) => { resolve(true); }) },
  ChangeStatusAndCatagories: (data: UpdateStatusAndCatagory) => { return new Promise((resolve, reject) => { resolve(true); }) },
  fetchCustomer: (id: string) => { return new Promise((resolve, reject) => { resolve({} as CustomerType); }) },
  getJobsForCustomer: (id: string) => { return new Promise((resolve, reject) => { resolve([] as JobRequest[]); }) },
  garages: [],
  serviceZipCodes: [],
  ads: [],
  azureGarages: [],
  servicableAreas: [],
};

export const JobContext = createContext<IJobContext>(initProps);
export const JobProvider = ({ children }: { children: any }) => {
  const {
    jobs,
    searchJobText,
    searchedJobs,
    setSearchJobText,
    setCurrentJob,
    currentJob,
    sendProposal,
    proposals,
    markAsCompleteTechnician,
    sendUpdatedProposal,
  } = useProposals();

  const { ChangeStatusAndCatagories, fetchCustomer, getJobsForCustomer, customerJobs, jobs: globalJobs, setCustomerJobs } = useJobs();
  const {ads}  =useAdvertisement();

  const { ratings } = useRating();

  const { categories } = useCategories();
  const { garages, serviceZipCodes, azureGarages, servicableAreas } = useGarages();

  return (
    <JobContext.Provider
      value={{
        jobs,
        globalJobs,
        searchJobText,
        searchedJobs,
        setSearchJobText,
        currentJob,
        setCurrentJob,
        sendProposal,
        proposals,
        ratings,
        categories,
        markAsCompleteTechnician,
        sendUpdatedProposal,
        ChangeStatusAndCatagories,
        fetchCustomer,
        getJobsForCustomer,
        customerJobs,
        garages,
        serviceZipCodes,
        ads,
        azureGarages,
        servicableAreas,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};
