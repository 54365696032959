import { UserContext } from "../../contexts/UserContext";
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useState, useEffect, useContext } from "react";
import { auth, db } from "../../firebase/firebaseutil";
import { ChatTopic } from "../../models/chat";

// Initialize Firebase
const COLLECTION = {
  CHAT: "job-chat",
  BLOCKED_USERS: "block",
  BLOCKED_USERS_FOR_USER: "blocked",
};

export default function useChatTopics() {
  
  const {user, dbTechnician} = useContext(UserContext);
  const [topics, setTopics] = useState<ChatTopic[]>([]);

  useEffect(() => {
    if (!user?.uid) {
      setTopics([]);
    }
  }, [user?.uid]);

  useEffect(() => {
    if (!user?.uid) return;
    const chatsQuery = query(
      collection(db, COLLECTION.CHAT)
    );
    const unsubscribe = onSnapshot(
      chatsQuery,
      querySnapshot => {
        const returnArray: ChatTopic[] = [];
        querySnapshot.forEach(item => {
          let data = {...item.data(), id: item?.id} as ChatTopic;
          if(data?.lastMessageAt?.length > 0){
            returnArray.push(data);
          }
        });
        // prevTopics => [...new Set([...prevTopics, ...returnArray])].sort((a,b) => b.createdAt > a.createdAt ? 1 : -1)
        const sortedTopics = returnArray.sort((a, b) =>
          b.lastMessageAt > a.lastMessageAt ? 1 : -1
        );
        if(dbTechnician?.isAdmin){
          setTopics(sortedTopics);
        }else{
          let technicianChats = sortedTopics.filter(item => item.partyIds?.includes(user?.uid))
          setTopics(technicianChats);
        }
      },
      err => {
        console.log(`Encountered error: ${err}`);
      }
    );
    return unsubscribe;
  }, [user?.uid, dbTechnician?.isAdmin]);

  const unreadTopicsCount = topics?.filter(
    t =>
      ((dbTechnician?.isAdmin && !t?.readAdmin) || (!dbTechnician?.isAdmin && t?.partyIds?.includes(user?.uid) && (!t?.readTechnician || !t?.readDaignostician) ))
  ).length;

  return {
    chatTopics: topics,
    unreadTopicsCount,
  };
}
